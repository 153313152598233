import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.sass";

function InformationSection({ button, title, description, className }) {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <Popover className="relative">
      <Popover.Button as="div" onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
        {button}
      </Popover.Button>
      <Transition
        show={isShowing}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className={classNames("absolute w-80 origin-top-left sm:w-72", styles.popoverIndex, className)}>
          <div className="block rounded-md shadow-md ring-1 ring-indigo-500 ring-opacity-5">
            <div className="p-4 rounded-md bg-gray-50 dark:bg-slate-700">
              <p className="text-left text-sm font-bold text-gray-900 dark:text-slate-100">{title}</p>
              <div className="text-left font-normal text-sm text-gray-500 dark:text-slate-400 whitespace-pre-wrap">
                {description}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

InformationSection.propTypes = {
  button: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.any,
  className: PropTypes.string,
};

export default InformationSection;
